@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

$_input-spacing-with-icon: calc(
  #{tokens.$mdrt-input-padding-horizontal} + #{tokens.$mdrt-input-size-icon} + #{tokens.$mdrt-input-gap-icon}
);

.wrapper {
  position: relative;
  width: 100%;
}

.input {
  @include mixins.ellipsis;

  $this: &;

  appearance: none;
  background-color: tokens.$mdrt-input-bg-fill;
  border: 1px solid tokens.$mdrt-input-border-enabled;
  border-radius: tokens.$mdrt-input-border-radius;
  caret-color: tokens.$mdrt-input-bg-cursor;
  color: tokens.$mdrt-input-text-value;
  display: block;
  font-size: var(--input-font-size);
  font-weight: var(--input-font-weight);
  line-height: var(--input-line-height);
  padding: calc(#{var(--spacing-16)} + #{tokens.$mdrt-input-gap-label})
    tokens.$mdrt-input-padding-horizontal
    calc(#{tokens.$mdrt-input-padding-vertical} - #{tokens.$mdrt-input-gap-label});
  transition: all 0.1s ease-in;
  width: 100%;

  &--without-label {
    line-height: var(--input-line-height-2);
    padding: tokens.$mdrt-input-padding-vertical tokens.$mdrt-input-padding-horizontal;

    + #{$this}__label {
      @include mixins.visually-hidden;
    }
  }

  &--with-leading-icon {
    padding-left: $_input-spacing-with-icon;

    &:not(#{$this}--without-label) {
      + #{$this}__label {
        padding-left: $_input-spacing-with-icon;
      }
    }
  }

  &--with-trailing-icon {
    padding-right: $_input-spacing-with-icon;

    &:not(#{$this}--without-label) {
      + #{$this}__label {
        padding-right: $_input-spacing-with-icon;
      }
    }
  }

  &__icon {
    align-items: center;
    color: tokens.$mdrt-input-icon-disabled;
    display: flex;
    height: tokens.$mdrt-input-size-icon;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: tokens.$mdrt-input-size-icon;

    &--leading {
      left: 0;
      margin-left: tokens.$mdrt-input-padding-horizontal;
    }

    &--trailing {
      margin-right: tokens.$mdrt-input-padding-horizontal;
      right: 0;
    }
  }

  &__label {
    color: tokens.$mdrt-input-text-placeholder;
    font: var(--mdrt-body-3-short);
    left: 1px;
    line-height: 1;
    padding-top: tokens.$mdrt-input-padding-vertical;
    position: absolute;
    top: 8px;
    transition: all 0.1s ease-in;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: tokens.$mdrt-input-text-placeholder;
    opacity: 1;
  }

  &[type='number'] {
    appearance: textfield;
  }

  &:hover {
    background-color: tokens.$mdrt-input-bg-hover;
    border-color: transparent;
    box-shadow: 0 0 0 tokens.$mdrt-input-border-thickness-hover tokens.$mdrt-input-border-hover;
  }

  &:not(:placeholder-shown),
  &:focus {
    + #{$this}__label {
      font-size: var(--font-size-2xs);
      top: 0;
    }
  }

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 tokens.$mdrt-input-border-thickness-focus tokens.$mdrt-input-border-focus;
    outline: 0;
  }

  &:read-only {
    background-color: tokens.$mdrt-input-bg-read-only;
    border-color: tokens.$mdrt-input-border-read-only;
    color: tokens.$mdrt-input-text-read-only;
  }

  &:disabled {
    background-color: tokens.$mdrt-input-bg-disabled;
    border-color: tokens.$mdrt-input-border-disabled;
    color: tokens.$mdrt-input-text-disabled;
  }
}
