@use '@shared/util-styling/mixins';

@mixin file-input-label {
  position: relative;
  text-wrap: nowrap;

  &:focus-within {
    outline: auto;
  }
}

.file-input-label {
  @include mixins.button;
  @include file-input-label;
}

.file-input-label-chip {
  @include mixins.chip;
  @include file-input-label;
}

.file-input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
}
