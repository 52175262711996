.score-gauge {
  $_score-margin: 14px;
  $_stroke-transition-style: stroke-dashoffset 0.8s;
  $_hover-transition-style: border-color 0.3s ease;
  $_border-style: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
  $_this: &;

  align-items: center;
  display: flex;
  flex-direction: column;

  &__indicator-container {
    height: 96px;
    margin: $_score-margin $_score-margin var(--spacing-8) $_score-margin;
    position: relative;
    width: 96px;
  }

  &__indicator-base {
    fill: transparent;
    stroke: var(--mdrt-color-borders-dark-01);
    stroke-linecap: round;
  }

  &__indicator-fill {
    fill: transparent;
    stroke-linecap: round;
    transition: $_stroke-transition-style;

    &--range-one {
      stroke: var(--mdrt-color-backgrounds-bg-secondary-fill);
    }

    &--range-two {
      stroke: var(--mdrt-color-backgrounds-bg-tertiary-fill);
    }

    &--range-three {
      stroke: var(--mdrt-color-backgrounds-bg-primary-fill-01);
    }

    &--secondary {
      stroke: var(--mdrt-color-borders-dark-03);
    }
  }

  &__score {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-headline-2);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__label {
    color: var(--mdrt-color-text-on-light-primary);
    font-size: var(--font-size-s);
  }

  &--secondary {
    background-color: var(--mdrt-color-backgrounds-bg-surface-02);
    border: var(--mdrt-border-thickness-01) solid transparent;
    border-radius: var(--mdrt-corner-radius-m);
    padding-bottom: var(--spacing-12);
    padding-top: var(--spacing-8);
    transition: $_hover-transition-style;
    width: 116px;

    #{$_this}__indicator-container {
      height: 56px;
      width: 56px;
    }

    #{$_this}__score {
      color: var(--mdrt-color-text-on-light-placeholder);
      font: var(--mdrt-title-1);
    }

    #{$_this}__label {
      color: var(--mdrt-color-text-on-light-tertiary);
      font-size: var(--font-size-xs);
      font-weight: var(--mdrt-font-weights-nunito-sans-1);
    }

    &:hover {
      border: $_border-style;

      #{$_this}__score {
        color: var(--mdrt-color-text-on-light-tertiary);
      }

      #{$_this}__label {
        color: var(--mdrt-color-text-on-light-tertiary);
      }

      #{$_this}__indicator-fill {
        &--secondary {
          stroke: var(--mdrt-color-borders-dark-04);
        }
      }
    }
  }

  &--active {
    background-color: var(--mdrt-color-backgrounds-bg-surface);
    border: $_border-style;

    #{$_this}__score {
      color: var(--mdrt-color-text-on-light-primary);
    }

    #{$_this}__label {
      color: var(--mdrt-color-text-on-light-primary);
    }
  }
}
