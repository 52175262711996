@use '@shared/util-styling/tokens';

.badge {
  background-color: tokens.$mdrt-badge-bg-fill-static;
  border-radius: tokens.$mdrt-badge-corner-radius;
  box-sizing: border-box;
  color: tokens.$mdrt-badge-text-fill-static;
  display: flex;
  flex-wrap: wrap;
  font: var(--badge-font);
  padding: tokens.$mdrt-badge-vertical-padding tokens.$mdrt-badge-horizontal-padding;

  &--secondary {
    background-color: tokens.$mdrt-badge-bg-fill-action;
    color: tokens.$mdrt-badge-text-fill-action;
  }
}
