@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

.card {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-12);
    margin-top: var(--spacing-16);
    height: 100%;
    justify-content: space-between;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-12);
  }

  &__image {
    display: block;
  }

  &__headline {
    font: var(--mdrt-headline-3);
    color: var(--mdrt-color-text-on-light-primary);
  }

  &__subheadline {
    font: var(--mdrt-title-2);
    color: var(--mdrt-color-text-on-light-primary);
  }

  &__description {
    color: var(--mdrt-color-text-on-light-secondary);

    &--title-2 {
      font: var(--mdrt-title-2);
    }

    &--title-3 {
      font: var(--mdrt-title-3);
    }

    @include mixins.breakpoint('medium') {
      min-height: 140px;
    }
  }
}
